import React from 'react';
import './Table.css';

/**
 * @typedef {{
 *  filter: string
 * value: string
 * }} FilterChange
 *
 * @typedef {{
 *  type: 'Reset'
 * }} Action
 * @param {Record<string, string>} state
 * @param {FilterChange | Action} action
 * @returns Record<string, string>
 */
function reducer(state, action) {
  if ('type' in action) {
    if (action.type === 'Reset') {
      const reset = Object.keys(state).reduce((acc, key) => {
        acc[key] = 'All';
        return acc;
      }, {});

      return reset;
    }
    return state;
  }
  return {
    ...state,
    [action.filter]: action.value,
  };
}

const filterOrdering = ['Level', 'Board', 'Year', 'Month', 'Tier', 'Paper', 'Total'];

const letterGrades = ['A*', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'U'];

const monthOrders = [
  'Januray',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

const dependentFilters = {
  Paper: ['Level', 'Board', 'Tier'],
  Board: ['Level'],
  Month: ['Level'],
  Tier: ['Level'],
};

/**
 * @typedef { string[] } Columns
 *
 * @param {{
 *   title: String,
 *   gradeBoundaries: {
 *      columns: Columns,
 *      data: {
 *          [K in Columns[number]]: string
 *      }[]
 *   }[],
 *   type: 'number' | 'letter'
 * }}
 * @returns
 */
export default function GradeBoundaries({ title, gradeBoundaries, paperOrdering }) {
  const [showing, setShowing] = React.useState(true);
  const type = letterGrades.some((grade) => gradeBoundaries[0].columns.includes(grade))
    ? 'letter'
    : 'number';
  const { grades, meta } = gradeBoundaries[0].columns.reduce(
    (acc, col) => {
      if (type === 'number') {
        if (Number.isInteger(parseInt(col))) acc.grades.push(col);
        else acc.meta.push(col);
      } else if (type === 'letter') {
        if (letterGrades.includes(col)) acc.grades.push(col);
        else acc.meta.push(col);
      }
      return acc;
    },
    { grades: [], meta: [] },
  );

  const combinedData = gradeBoundaries.map((gradeBoundary) => gradeBoundary.data).flat();

  /**
   * @type {Record<string, string[]>}
   */
  const initialFilters = {};

  const [activeFilters, dispatch] = React.useReducer(
    reducer,
    meta.reduce((acc, header) => {
      if (!['Total'].includes(header)) acc[header] = 'All';
      return acc;
    }, {}),
  );

  const [viewType, setViewType] = React.useState('rawMark');

  const filteredData = combinedData.filter((row) => {
    return Object.entries(activeFilters).every(([key, value]) => {
      if (value === 'All') return true;
      return row[key] === value;
    });
  });

  const handleDependentFilters = (acc, key, value, dependentFilters) => {
    const nonAllFilters = dependentFilters.filter((filter) => activeFilters[filter] !== 'All');
    //If there are filters that don't have 'All'
    if (nonAllFilters.length) {
      //Unique values based on the filters that are not set to 'All'
      const validData = combinedData.filter((data) => {
        return nonAllFilters.every((filter) => data[filter] === activeFilters[filter]);
      });

      if (validData.length) {
        //If the current value is part of the pseudo-filtered data
        if (validData.some((data) => data[key] === value)) {
          if (!(key in acc)) acc[key] = ['All', value];
          else if (!acc[key].includes(value)) acc[key].push(value);
        }
      } else {
        acc[key] = ['All'];
      }
    } else {
      if (!(key in acc)) acc[key] = ['All', value];
      else if (!acc[key].includes(value)) acc[key].push(value);
    }
  };

  let filters = React.useMemo(() => {
    const preSortedFilters = combinedData.reduce((acc, row) => {
      for (const [key, value] of Object.entries(row)) {
        if (meta.includes(key) && !['Total'].includes(key) && value) {
          if (Object.keys(dependentFilters).includes(key)) {
            handleDependentFilters(acc, key, value, dependentFilters[key]);
          } else {
            //If filter isnt dependent on any other filters, add filters like normal
            if (!(key in acc)) acc[key] = ['All', value];
            else if (!acc[key].includes(value)) acc[key].push(value);
          }
        }
      }
      return acc;
    }, initialFilters);
    preSortedFilters['Paper'] = preSortedFilters['Paper'].sort((a, b) => {
      const indexA = paperOrdering.indexOf(a);
      const indexB = paperOrdering.indexOf(b);

      if (a === 'All') return -1;
      if (b === 'All') return 1;
      if (indexA === -1 && indexB === -1) {
        return 0; // Maintain original order
      } else if (indexA === -1) {
        return 1; // b comes before a
      } else if (indexB === -1) {
        return -1; // a comes before b
      } else {
        return indexA - indexB;
      }
    });
    preSortedFilters['Year'] = preSortedFilters['Year'].sort((a, b) => {
      return a - b;
    });

    preSortedFilters['Month'] = preSortedFilters['Month'].sort((a, b) => {
      const aIndex = monthOrders.findIndex((month) => month.includes(a));
      const bIndex = monthOrders.findIndex((month) => month.includes(b));

      if (a === 'All') return -1;
      if (b === 'All') return 1;
      if (aIndex === -1) return 1;
      if (bIndex === -1) return -1;
      return aIndex - bIndex;
    });

    return preSortedFilters;
  }, [filteredData]);

  const renderCellValue = (value, total) => {
    if (viewType === 'rawMark') return `${value}`;
    else if (viewType === 'percentage') return `${((value / total) * 100).toFixed(0)}%`;
  };

  return (
    <>
      <h2 className="grade-boundary-title" id={title.split(' ').join('-').toLowerCase()}>
        {title}
      </h2>
      <button className="button ml-0 my-1" onClick={() => setShowing((prev) => !prev)}>
        {showing ? 'Hide' : 'Show'} grade boundaries
      </button>
      {showing ? (
        <>
          <div className="srow mb-1">
            <label htmlFor="rawMark" style={{ marginBottom: 0, marginRight: '2px' }}>
              Raw Mark
            </label>
            <input
              type="radio"
              name="inputType"
              id="rawMark"
              checked={viewType === 'rawMark'}
              onChange={() => setViewType('rawMark')}
            />
            <label
              htmlFor="percentage"
              style={{ marginBottom: 0, marginLeft: '2px', marginRight: '8px' }}
            >
              Percentage
            </label>
            <input
              type="radio"
              name="inputType"
              id="percentage"
              checked={viewType === 'percentage'}
              onChange={() => setViewType('percentage')}
            />
          </div>

          <div className="srow mb-1" style={{ gap: '0.25rem' }}>
            {Object.entries(filters)
              .sort(([aKey], [bKey]) => filterOrdering.indexOf(aKey) - filterOrdering.indexOf(bKey))
              .map(([key, options], i) => (
                <div className="scolumn narrow" key={`filter-${i}`}>
                  <label htmlFor={key}>{key}</label>
                  <select
                    id={key}
                    className="dropdown"
                    value={activeFilters[key]}
                    onChange={(e) => dispatch({ filter: key, value: e.target.value })}
                  >
                    {options.map((option, j) => (
                      <option key={`option-${i}-${j}`} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>
                </div>
              ))}
          </div>
          <button className="button ml-0" onClick={() => dispatch({ type: 'Reset' })}>
            Reset filters
          </button>
          <div className="table-container">
            {filteredData.length ? (
              <table className="table grade-boundary">
                <thead>
                  <tr>
                    <th
                      colSpan={meta.length}
                      style={{
                        textAlign: 'center',
                        backgroundColor: '#fd99c5',
                        border: '1px solid black',
                      }}
                    >
                      Exam
                    </th>
                    <th
                      colSpan={grades.length}
                      style={{
                        textAlign: 'center',
                        backgroundColor: '#fd99c5',
                        border: '1px solid black',
                      }}
                    >
                      Grade
                    </th>
                  </tr>
                  <tr>
                    {meta.map((header, i) => (
                      <th
                        key={`header-meta-${i}`}
                        style={{ textAlign: 'center', border: '1px solid black' }}
                      >
                        {header}
                      </th>
                    ))}
                    {grades.map((grade, i) => (
                      <th
                        key={`header-grade-${i}`}
                        style={{ textAlign: 'center', border: '1px solid black' }}
                      >
                        {grade}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {filteredData.map((row) => (
                    <tr>
                      {meta.map((header, i) => (
                        <td
                          key={`data-meta-${i}`}
                          data-label={header}
                          className={i === meta.length - 1 ? 'last-meta-cell' : ''}
                          style={{
                            textAlign: 'center',
                          }}
                        >
                          {row[header]}
                        </td>
                      ))}
                      {grades.map((grade, i) => (
                        <td
                          key={`data-grade-${i}`}
                          data-label={grade}
                          className={!row[grade] || row[grade]?.trim() === '-' ? 'no-data-bg' : ''}
                          style={{
                            textAlign: 'center',
                          }}
                        >
                          {row[grade]?.trim() !== '-' && row[grade]?.trim()
                            ? renderCellValue(row[grade], row['Total'])
                            : ''}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <p className="no-results p-1 mt-1">No results found</p>
            )}
          </div>
        </>
      ) : null}
    </>
  );
}
