import React from 'react';
import ReactMarkdown from 'react-markdown';
import { useSelector } from 'react-redux';
import { graphql, navigate } from 'gatsby';
import { MathJaxContext, MathJax } from 'better-react-mathjax';
import { Layout, SEO } from '../components';
import DocumentsList from '../components/documents/DocumentsList';
import TopicTable from '../components/TopicTable';

import Clouds from '../assets/images/clouds.svg';
import { scroller } from 'react-scroll';

import { CountdownTimer } from '../components/UI/FlipClock';
import VideoPlayer from '../components/VideoPlayer';
import GradeBoundaries from 'components/GradeBoundaries';

const slugify = (text) =>
  text
    .toString()
    .toLowerCase()
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(/[^\w\-]+/g, '') // Remove all non-word chars
    .replace(/\-\-+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, ''); // Trim - from end of text

const ModuleSubCategoryDocumentsPage = ({
  data: {
    subCategoryPage: {
      module,
      heading,
      description,
      description2,
      seoKeywords,
      subscriptionforumbuttons,
      video,
      videos,
      youtubeVideo,
      topicTables,
      seo,
      gradeBoundariesDescription,
      gradeBoundaryTables,
    },
    datocms: {
      docCategoryPage,
      documentsCount: { count: totalDocuments },
    },
    timers,
  },
  pageContext: { documents },
}) => {
  const [timersDisplayedOnBottom, setTimersDisplayedOnBottom] = React.useState(false);
  const user = useSelector((state) => state.user.data);
  const isSubscribed = user?.subscribed ?? false;

  const showSubscriptionButton = subscriptionforumbuttons?.[0]?.displaysubscription ?? false;
  const showForumButton = subscriptionforumbuttons?.[0]?.displayforum ?? false;
  const subscriptionBackgroundColor =
    subscriptionforumbuttons?.[0]?.subbackgroundcolor?.hex ?? '#23D4FF';
  const subscriptionTextColor = subscriptionforumbuttons?.[0]?.subtextcolor?.hex ?? '#000000';
  const forumBackgroundColor =
    subscriptionforumbuttons?.[0]?.forumbackgroundcolor?.hex ?? '#FD99C4';
  const forumTextColor = subscriptionforumbuttons?.[0]?.forumtextcolor?.hex ?? '#000000';

  const formattedTables = topicTables.map(({ table, title, description, featuredDocuments }) => ({
    title,
    description,
    featuredDocuments,
    table: JSON.parse(table),
  }));

  const combinedDocuments = React.useMemo(
    () => documents.filter((doc) => !doc.isFeatured && !doc.freeSample),
    [documents],
  );
  const featuredDocuments = React.useMemo(
    () => documents.filter((doc) => doc.isFeatured),
    [documents],
  );
  const freeDocuments = React.useMemo(
    () => documents.filter((doc) => doc.freeSample && !doc.freeDocument),
    [documents],
  );

  const docCategories = React.useMemo(() => {
    const objDocCategories = {};
    docCategoryPage?.docCategories.map(({ id, name }) => {
      objDocCategories[slugify(name)] = {
        name,
        content:
          docCategoryPage?.docCategoryContent.find(({ docCategory }) => docCategory.name === name)
            ?.content ?? '',
        docs: combinedDocuments.filter((doc) => doc.docCategory?.originalId == id),
      };
    });

    return objDocCategories;
  }, [combinedDocuments]);

  const uncategorisedDocs = React.useMemo(
    () =>
      combinedDocuments.filter(
        (doc) =>
          !doc.docCategory || !docCategories.hasOwnProperty(slugify(doc.docCategory?.name || '')),
      ),
    [combinedDocuments],
  );

  const orderedTimers = React.useMemo(() => {
    return timers?.group?.map(({ edges, examBoard }) => {
      const groups = {};
      for (const {
        node,
        node: { title },
      } of edges) {
        const regex = /\d([A-Za-z])(?![A-Za-z])/;
        const matched = title?.match(regex);

        if (!matched) {
          if (!groups.unmatched) groups.unmatched = [];
          groups.unmatched = [...groups.unmatched, node];
          continue;
        }

        const [_, letter] = matched;
        if (!groups[letter]) {
          groups[letter] = [];
        }
        groups[letter].push(node);
      }
      const formattedEdges = Object.values(groups).map((papers) =>
        papers.sort((a, b) => b.title - a.title),
      );
      return {
        edges: formattedEdges,
        examBoard,
      };
    });
  }, [timers]);

  const numOfTimers = timers?.group?.reduce((total, { edges }) => (total += edges?.length ?? 0), 0);

  React.useEffect(() => {
    setTimersDisplayedOnBottom(window.location.pathname.includes('exam-timetable-countdown'));
  }, []);

  const renderFeaturedDocuments = () => {
    return (
      !!featuredDocuments.length && (
        <div className="documents-list">
          <div className="category-breadcrumb-heading">
            <h3>Featured documents:</h3>
          </div>
          <DocumentsList documents={featuredDocuments} />
        </div>
      )
    );
  };

  const renderFreeDocuments = () =>
    freeDocuments.length > 0 && (
      <div className="documents-list">
        <div className="category-breadcrumb-heading">
          <h3>Free samples:</h3>
        </div>
        <DocumentsList documents={freeDocuments} />
      </div>
    );

  const renderDocumentsHeading = () =>
    documents[0] && (
      <div className="category-breadcrumb-heading">
        <h3 style={{ marginBottom: 15 }}>
          {documents[0].documentCategory[documents[0].documentCategory.length - 1].name}:
        </h3>
        <p>Total of {totalDocuments}</p>
      </div>
    );

  const renderCategorizedDocs = () => {
    return (
      <>
        {Object.keys(docCategories).map((key) => {
          const category = docCategories[key];
          return (
            <>
              {!!category.docs.length ? (
                <div className={`scroll-doc-category-${key}`} key={key}>
                  <h3
                    style={{
                      textAlign: 'center',
                      marginBottom: '35px',
                      marginTop: '35px',
                    }}
                  >
                    {category.name}
                  </h3>
                  <DocumentsList documents={category.docs} />
                  <div dangerouslySetInnerHTML={{ __html: category.content }} />
                </div>
              ) : (
                <></>
              )}
            </>
          );
        })}
        {!!uncategorisedDocs.length && (
          <div>
            <div style={{ textAlign: 'center', marginBottom: '100px', marginTop: '35px' }}>
              {/* Uncategorised */}
            </div>
            <DocumentsList documents={uncategorisedDocs} />
          </div>
        )}
      </>
    );
  };

  const renderDocCategoryButtons = () => {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>
        <div style={{ display: 'flex', justifyContent: 'center', width: '70%', flexWrap: 'wrap' }}>
          {Object?.keys(docCategories)?.map((key) => {
            const item = docCategories[key];
            const hasDocs = docCategories[key].docs.length > 0;
            return (
              <button
                key={item.id}
                style={{
                  marginTop: '7px',
                  marginRight: '7px',
                  backgroundColor: hasDocs ? '#fd99c5' : '#fd99c580',
                  color: 'white',
                  fontWeight: 'bold',
                  padding: '3px',
                }}
                disabled={!hasDocs}
                onClick={() => {
                  scroller.scrollTo(`scroll-doc-category-${slugify(item.name)}`, {
                    duration: 1000,
                    smooth: 'easeOutQuad',
                    offset: -50,
                  });
                }}
              >
                {item.name}
              </button>
            );
          })}
        </div>
      </div>
    );
  };

  const renderActionButtons = () => {
    if (!showSubscriptionButton && !showForumButton) return;
    return (
      <div className="srow x-centered">
        {showSubscriptionButton && !isSubscribed && (
          <button
            onClick={() => navigate('/subscriptions')}
            className="w-nav-brand sign-in-badge forum-sub-button"
            style={{
              backgroundColor: subscriptionBackgroundColor,
              color: subscriptionTextColor,
            }}
          >
            <span class="2">Join Now</span>
          </button>
        )}
        {showForumButton && (
          <button
            onClick={() => navigate('/forum')}
            className="w-nav-brand sign-in-badge forum-sub-button"
            style={{
              backgroundColor: forumBackgroundColor,
              color: forumTextColor,
            }}
          >
            <span class="2">Forum</span>
          </button>
        )}
      </div>
    );
  };

  const allVideos = [
    ...(video?.video?.streamingUrl ? [video?.video?.streamingUrl] : []),
    ...(youtubeVideo ? youtubeVideo.split(',') : []),
    ...(videos?.length ? videos.map((vid) => vid.video.streamingUrl) : []),
  ];

  return (
    <MathJaxContext>
      <Layout>
        <SEO
          description={seo && seo.description}
          image={seo && seo.image}
          title={seo && seo.title}
          seoKeywords={seoKeywords}
        />
        <div>
          <div className="container">
            {!timersDisplayedOnBottom && !!orderedTimers?.length && (
              <div className={`srow mobile:x-centered ${numOfTimers < 4 ? 'x-centered' : ''}`}>
                {orderedTimers.map(
                  ({ edges }) =>
                    !!edges?.length &&
                    edges.map((nodes) =>
                      nodes.map(({ endDate, title, daysOnly }, i) => (
                        <div className="column narrow mb-1" key={`clock-top-${i}`}>
                          <CountdownTimer endDate={endDate} title={title} daysOnly={daysOnly} />
                        </div>
                      )),
                    ),
                )}
              </div>
            )}
            <div>
              <div style={{ float: 'right', maxWidth: '50%' }}>
                <img src={module.image.url} alt={module.image.alt} />
                {renderActionButtons()}
              </div>
              <div className="content-block">
                <h1 className="content-h2">{module.title}</h1>
                <h2>{heading.name}</h2>
                <br />
                {allVideos.length
                  ? allVideos.map((src) => (
                      <VideoPlayer className="video-player" isSmall src={src} />
                    ))
                  : null}
                <MathJax hideUntilTypeset="first">
                  {description && <ReactMarkdown className="paragraph" children={description} />}
                  {!description && description2 && (
                    <div dangerouslySetInnerHTML={{ __html: description2 }} />
                  )}
                </MathJax>
              </div>
            </div>
          </div>
          <img src={Clouds} alt="Clouds" className="pattern-left" />
        </div>
        <article className="content-section">
          <div className="srow x-center mb-1">
            {gradeBoundaryTables.map(({ title }) => (
              <div className="scolumn narrow">
                <a className="button" href={`#${title.split(' ').join('-').toLowerCase()}`}>
                  Go to {title}
                </a>
              </div>
            ))}
          </div>
          {gradeBoundaryTables.map((gradeBoundaryTable) => (
            <>
              <GradeBoundaries
                title={gradeBoundaryTable.title}
                gradeBoundaries={gradeBoundaryTable.gradeBoundaryData.map((data) =>
                  JSON.parse(data.gradeBoundaries),
                )}
                paperOrdering={JSON.parse(gradeBoundaryTable.paperOrdering)}
              />
            </>
          ))}
          {gradeBoundariesDescription && (
            <div dangerouslySetInnerHTML={{ __html: gradeBoundariesDescription }} />
          )}
          <div className="content-block container">
            <div className="topic-tables-container">
              <div className="srow x-center mb-1">
                {formattedTables
                  .filter(({ table }) => table?.data)
                  .map(({ title }) => (
                    <div className="scolumn narrow">
                      <a href={`#${title.split(' ').join('-').toLowerCase()}`} className="button">
                        Go to {title}
                      </a>
                    </div>
                  ))}
              </div>

              {formattedTables
                .filter(({ table }) => table?.data)
                .map(({ table, title, description, featuredDocuments }) => (
                  <TopicTable
                    table={table}
                    title={title}
                    description={description}
                    featuredDocuments={featuredDocuments}
                  />
                ))}
            </div>

            {renderFeaturedDocuments()}
            {renderFreeDocuments()}
            <div className="document-list-container">
              {docCategoryPage?.isEnabled && renderDocCategoryButtons()}
              {renderDocumentsHeading()}
              {docCategoryPage?.isEnabled && renderCategorizedDocs()}
              {!docCategoryPage?.isEnabled && (
                <DocumentsList documents={combinedDocuments} showNoData type={heading.name} />
              )}
            </div>

            {timersDisplayedOnBottom && !!orderedTimers?.length
              ? orderedTimers.map(
                  ({ edges, examBoard }, i) =>
                    !!edges?.length && (
                      <div key={`wrapper-bottom-${i}`}>
                        {examBoard !== 'No exam board' && (
                          <p className="1.25 bold mb-1">{examBoard}</p>
                        )}
                        {edges.map((nodes, j) => (
                          <div className="srow mobile:x-centered" key={`container-bottom-${j}`}>
                            {nodes.map(({ endDate, title, daysOnly }, k) => (
                              <div className="column narrow mb-1" key={`clock-bottom-${k}`}>
                                <CountdownTimer
                                  endDate={endDate}
                                  title={title}
                                  daysOnly={daysOnly}
                                />
                              </div>
                            ))}
                          </div>
                        ))}
                      </div>
                    ),
                )
              : null}
          </div>
        </article>
      </Layout>
    </MathJaxContext>
  );
};

export default ModuleSubCategoryDocumentsPage;

const query = graphql`
  query SUB_CATEGORY_DOCUMENTS_PAGE_QUERY(
    $module: DATOCMS_ItemId
    $id: String!
    $categories: [DATOCMS_ItemId]
    $pathName: String
  ) {
    timers: allDatoCmsCountdownTimer(filter: { path: { eq: $pathName } }, sort: { fields: title }) {
      group(field: examBoard) {
        edges {
          node {
            daysOnly
            endDate
            examBoard
            path
            title
          }
        }
        examBoard: fieldValue
      }
    }
    subCategoryPage: datoCmsModuleSubCategoryPage(id: { eq: $id }) {
      module {
        title
        image {
          alt
          url
        }
      }
      heading {
        name
      }
      video {
        video {
          streamingUrl
        }
      }
      videos {
        video {
          streamingUrl
        }
      }
      topicTables {
        title
        description
        table
        featuredDocuments {
          title
          fileUrl
        }
      }
      youtubeVideo
      description
      gradeBoundaryTables {
        title
        paperOrdering
        gradeBoundaryData {
          gradeBoundaries
        }
      }
      gradeBoundariesDescription
      description2
      showSubCategories
      subscriptionforumbuttons {
        displayforum
        displaysubscription
        subbackgroundcolor {
          hex
        }
        subtextcolor {
          hex
        }
        forumbackgroundcolor {
          hex
        }
        forumtextcolor {
          hex
        }
      }
      subCategoryList {
        subCategoryHeading {
          id
          name
          slug
        }
        subCategoryOptionsList {
          name
          slug
        }
      }
      seoKeywords {
        keyword
      }
      seo {
        description
        title
        image {
          url
        }
      }
    }
    datocms {
      documentsCount: _allDocumentsMeta(
        filter: {
          documentCategory: { allIn: $categories }
          module: { eq: $module }
          isFeatured: { eq: "false" }
          freeSample: { eq: "false" }
        }
      ) {
        count
      }
      docCategoryPage: docCategoryPage(
        filter: { module: { eq: $module }, page: { allIn: $categories } }
      ) {
        docCategories {
          name
          id
        }
        docCategoryContent {
          content
          docCategory {
            name
          }
        }
        isEnabled
      }
    }
  }
`;
export { query };
